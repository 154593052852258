@import "src/index";

.hello-screen {
  .top {
    background-color: #fff;
    background-image: url("/images/PD-submission-bg-2x.png");
    background-size: cover;
    margin: -180px 0 0;
    padding: 60px 0px 24px;
  }

  .welcome-message {
    padding-top: 24px;
  }

  .small {
    margin-bottom: 24px;
  }

  .summary {
    padding: 16px 16px 1px;
    margin: 0px 16px 24px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.15);
    text-align: left;

    h3 {
      margin-left: -20px;
      margin-right: -20px;
      text-align: center;
    }

    &__row {
      position: relative;
      padding-left: 30px;
      margin-bottom: 16px;
      min-height: 14px;
      @extend .small;
    }

    &__bullet {
      position: absolute;
      left: 0;
      top: 0px;
      @extend .small;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border-radius: 100%;
      text-align: center;
      line-height: 18px;
      color: #f890ac;
    }
  }

  .secret-link-to-map {
    a {
      text-decoration: none;
      display: inline-block;
      width: 100px;
      margin-top: 32px;
    }
  }
}
