.indicators {
  margin-top: -64px;
  margin-bottom: 24px;
  text-align: center;
}

.indicator {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}

.indicator--highlighted {
  background-color: #ffbaba;
}
