.thankyou-screen {
  text-align: center;

  a {
    color: #fff;
  }
  .top {
    background-color: #fff;
    background-image: url("/images/PD-submission-bg-2x.png");
    background-size: cover;
    margin: -180px 0 0;
    padding-top: 80px;
    padding-bottom: 24px;
  }

  h2 {
    //font-size: 27px;
    //line-height: 34px;

    font-size: 20px;
    line-height: 30px;
  }

  .thankyou-message {
    margin: 24px 0 24px;
  }

  .share {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 10px;

    background: 50% 50% no-repeat transparent;
    background-size: contain;
    text-decoration: none;
  }
  .pinkdotsgweb {
    background-image: url("/images/pinkdotsgweb.png");
  }
  .pinkdotsgfb {
    background-image: url("/images/pinkdotsgfb.png");
  }
  .pinkdotsgig {
    background-image: url("/images/pinkdotsgig.png");
  }
  .pinkdotsgyt {
    background-image: url("/images/pinkdotsgyt.png");
  }
  .pinkdotsgtw {
    background-image: url("/images/pinkdotsgtw.png");
  }
}
