.map-screen {

  .messagesBottomOverlay {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60vh;
    background: linear-gradient(0deg, #2F2D2D 25.8%, rgba(47, 45, 45, 0) 91.6%);
  }

  .messagesBottomContent {
    position: fixed;
    left: 50%;
    bottom: 32px;
    width: 326px;
    max-height: 30vh;
    margin-left: -163px;

    @media (min-width: 1025px) {
      width: 768px;
      margin-left: -384px;
    }
  }

  .mapDot {
    position: absolute;
    transform: translate(-50%, -50%);
    //width: 16px;
    //height: 16px;
    //width: 10px;
    //height: 10px;
    //border-radius: 16px;
    //background-color: #F890AC;
    //background: url("../assets/pd1.png") 50% 50% no-repeat transparent;
    //background-size: contain;

    //box-shadow: 0 0 0 5px rgba(254, 0, 250, 0.3)

    &.mapDot--active {
      height: 48px;
      width: 48px;

      > div {
        //display: none;

        width: 100%;
        height: 100%;
        background: url("../assets/map/active-icon/pinkdot-m1@2x.png") 50% 50% no-repeat transparent;
        background-size: contain;

        &.pinkie--1 { background-image: url("../assets/map/active-icon/pinkdot-m1@2x.png"); }
        &.pinkie--2 { background-image: url("../assets/map/active-icon/pinkdot-m2@2x.png"); }
        &.pinkie--3 { background-image: url("../assets/map/active-icon/pinkdot-m3@2x.png"); }
        &.pinkie--4 { background-image: url("../assets/map/active-icon/pinkdot-m4@2x.png"); }
        &.pinkie--5 { background-image: url("../assets/map/active-icon/pinkdot-m5@2x.png"); }
        &.pinkie--6 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m1@2x.png"); }
        &.pinkie--7 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m2@2x.png"); }
        &.pinkie--8 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m3@2x.png"); }
        &.pinkie--9 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m4@2x.png"); }
        &.pinkie--10 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m5@2x.png"); }
        &.pinkie--11 { background-image: url("../assets/map/active-icon/pinkies13/pd13-m6@2x.png"); }

        &.bounce {
          animation-duration: 1.2s;
          animation-iteration-count: 1;
          transform-origin: bottom center;
          animation-name: bounce;
          animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
        }
        @keyframes bounce {
          0%   { transform: scale(1,1)      translateY(0); }
          10%  { transform: scale(1.1,.9)   translateY(0); }
          30%  { transform: scale(.9,1.1)   translateY(-30px); }
          50%  { transform: scale(1.05,.95) translateY(0); }
          57%  { transform: scale(1,1)      translateY(-5px); }
          64%  { transform: scale(1,1)      translateY(0); }
          100% { transform: scale(1,1)      translateY(0); }
        }

      }

      &.zoom--14, &.zoom--15 {
        height: 56px;
        width: 56px;
      }

      &.zoom--16 {
        height: 64px;
        width: 64px;
      }

      &.zoom--17 {
        height: 72px;
        width: 72px;
      }

    }

    > .dot {
      //background: url("../assets/map/pinkdot-50px.png") 50% 50% no-repeat transparent;
      //background-size: contain;
      border-radius: 32px;
      //background-color: red;
    }

    > .cluster {
      background: url("../assets/map/pinkdot-10.png") 50% 50% no-repeat transparent;
      background-size: contain;
      border-radius: 100%;
      &.cluster--indicators {
        border: 1px solid #FFF;
      }
    }

    > .cluster-10 {
      background-image: url("../assets/map/pinkdot-10.png");
      &.cluster--indicators {
        background-color: rgba(90, 199, 59, 0.30);
      }
    }

    > .cluster-20 {
      background-image: url("../assets/map/pinkdot-20.png");
      &.cluster--indicators {
        background-color: rgba(43, 189, 148, 0.30);
      }
    }

    > .cluster-30 {
      background-image: url("../assets/map/pinkdot-30.png");
      &.cluster--indicators {
        background-color: rgba(35, 104, 208, 0.30);
      }
    }

    > .cluster-40 {
      background-image: url("../assets/map/pinkdot-40.png");
      &.cluster--indicators {
        background-color: rgba(103, 36, 210, 0.30);
      }
    }

    > .cluster-50 {
      background-image: url("../assets/map/pinkdot-50.png");
      &.cluster--indicators {
        background-color: rgba(210, 107, 29, 0.30);
      }
    }

    > .cluster-60 {
      background-image: url("../assets/map/pinkdot-50.png");
      &.cluster--indicators {
        background-color: rgba(210, 29, 86, 0.30);
      }
    }

    > .cluster-70 {
      background-image: url("../assets/map/pinkdot-50.png");
      &.cluster--indicators {
        background-color: rgba(238, 182, 42, 0.30);
      }
    }

    .cluster-bucket--0 { transform: rotateZ(32deg);}
    .cluster-bucket--1 { transform: rotateZ(64deg);}
    .cluster-bucket--2 { transform: rotateZ(96deg);}
    .cluster-bucket--3 { transform: rotateZ(128deg);}
    .cluster-bucket--4 { transform: rotateZ(160deg);}
    .cluster-bucket--5 { transform: rotateZ(192deg);}
    .cluster-bucket--6 { transform: rotateZ(224deg);}
    .cluster-bucket--7 { transform: rotateZ(256deg);}
    .cluster-bucket--8 { transform: rotateZ(288deg);}
    .cluster-bucket--9 { transform: rotateZ(320deg);}

    .count {
      font-size: 16px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // region unique dots.
    &.mapDot--foundin {
      > .dot {
        background: url("../assets/map/foundin-heart.png") 50% 50% transparent;
        background-size: contain;
      }

      &.mapDot--active {
        > div {
          &.pinkie {
            background-image: url("../assets/map/foundin-heart.png");
          }
        }
      }
    }
    // endregion
  }


  .message-wrapper {
    margin-bottom: 80px;
  }

  .message-animation {
    position: absolute;
    top: -80px;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    // Derived from lottie animation dimension of 992x592,
    height: 238px;
    pointer-events: none;

    svg {
      //width: 200% !important;
      //height: 200% !important;
      //transform: translate3d(-200px, 0px, 0px) !important;
    }
  }

  .message {
    // Position relative so that it would not be occluded by the lottie animation which is position absolute.
    position: relative;
    text-align: center;

    h3 {
      height: 22px;
      //width: 71px;
      color: #F85984;
      font-size: 16px;
      font-weight: 900;
      line-height: 22px;
      margin-bottom: 16px;
    }

    p {
      max-height: 3 * 32px;
      overflow: auto;
      color: #FFF;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }

  .brand {
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: 0;
    //margin: 48px auto 0;
    text-align: center;

    .left, .right {
      display: inline-block;
      width: 80px;
      height: auto;
      cursor: pointer;
      vertical-align: middle;
      font-size: 14px;
      text-decoration: underline;

      .icon {
        width: 16px;
        height: 16px;
        background: url("../assets/map/pdlogo-wh-left.png") 50% 50% no-repeat transparent;
        background-size: contain;
      }
    }

    .left {
      padding: 0 24px 0 0;
    }

    .right {
      padding: 0 0 0 24px;
    }

    .logo {
      display: inline-block;
      width: 140px;
      height: 40px;
      background: url("../assets/map/pd-map-brand-2021.png") 50% 50% no-repeat transparent;
      background-size: contain;
      vertical-align: middle;
    }
  }

  .mascot {
    position: absolute;
    top: 16px;
    left: 16px;
    height: 56px;
    width: 56px;
    background: url("../assets/map/pd-mascot-112px.png") 50% 50% no-repeat transparent;
    background-size: contain;
    pointer-events: none;
  }

  .zoom-controls {
    position: absolute;
    top: 16px;
    height: 48px;
    width: 48px+8px+48px;
    left: 50%;
    margin-left: (48px+8px+48px) / -2;
    text-align: center;

    &__in, &__out {
      display: inline-block;
      width: 42px;
      height: 48px;
      padding: 10px 4px 10px 10px;
      cursor: pointer;

      .icon {
        width: 28px;
        height: 28px;
        background: url("../assets/map/pd-zoomin-80px.png") 50% 50% no-repeat transparent;
        background-size: contain;
      }
    }
    &__out {
      padding: 10px 10px 10px 4px;
      .icon {
        background-image: url("../assets/map/pd-zoomout-80px.png")
      }
    }
  }

  .stats {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 16px;
    font-size: 10px;
    line-height: 12px;
    color: #EEEEEE70;

    //a {
    //  text-decoration: underline;
    //  cursor: pointer;
    //}

    button {
      border: none;
      padding: 0;
      font-size: inherit;
      letter-spacing: inherit;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.map-screen--2020 {
  .brand {
    .logo {
      background-image: url("../assets/map/pd-map-brand-2020.png");
    }
  }
}

.map-screen--2021 {
  .brand {
    .logo {
      background-image: url("../assets/map/pd-map-brand-2021.png");
    }
  }
}

.hidden-controls {
  position: absolute;
  right: 0;
  top: 0;

  .fullscreen {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }

}

.map-screen--fullscreen {
  .zoom-controls, .messagesBottomContent, .messagesBottomOverlay {
    display: none;
  }
}
