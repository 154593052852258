@import "../index";

.location-screen {
  text-align: center;

  h2 {
    margin-top: -120px;

    font-size: 20px;
    line-height: 30px;
  }

  .map {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: 20px;
  }

  .note {
    @extend .small;
    margin-top: 16px;
  }
}
