@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap");

// Reset from https://alligator.io/css/minimal-css-reset/
html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

// End reset from https://alligator.io/css/minimal-css-reset/

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  background-color: #f890ac;
  color: #fff;
}

#root {
  min-height: 100vh;
}

h1 {
  font-size: 80px;
  line-height: 109px;
  font-weight: 900;
  margin-bottom: 16px;
}

h2 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 900;
}

h3 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 900;
}

.big {
  font-size: 24px;
  line-height: 24px;
  font-weight: 900;
}

.small {
  font-size: 14px;
  line-height: 20px;
}

.smallest {
  font-size: 10px;
  font-weight: 900;
  line-height: 14px;
}

a,
button {
  outline: none;
}

.mt24 {
  margin-top: 24px;
}

.mb4 {
  margin-bottom: 4px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb32 {
  margin-bottom: 32px;
}

input {
  display: block;
  margin: 0;
  width: 100%;

  padding: 6px 12px;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  outline: none;

  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

textarea {
  padding: 12px 12px;
  min-height: 200px;
  border-radius: 5px;
  background-color: #fff;
  border: none;
  outline: none;

  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

button {
  padding: 6px 24px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 900;
  line-height: 21px;
  background: transparent;
  color: #fff;
  letter-spacing: 1px;
}

.actions {
  margin: 24px 0px;
  text-align: center;
}

.center {
  text-align: center;
}

.mascot {
  display: block;
  width: 100px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  display: block;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9999;

  .heading {
    margin: 200px auto 32px;
    color: #f890ac;
  }
}

// Start loader from https://loading.io/css/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f890ac;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
// End loader from https://loading.io/css/
