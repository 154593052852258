.holding-screen {
  width: 100%;
  height: 100vh;
  padding-top: 160px;
  background: url("../assets/sg-map-dark.png") 50% 50% no-repeat transparent;
}

.holding-screen-shade {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.holding-screen-message {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -60%);
  left: 50%;
  max-width: 320px;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 640px;
  }

  h1 {
    font-size: 26px;
    color: #F890AC;
    line-height: initial;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: initial;
    margin-bottom: 56px;

    @media (min-width: 768px) {
      font-size: 26px;
    }
  }

  .logo {
    display: block;
    width: 220px;
    height: 62px;
    margin: 16px auto;
    background: url("../assets/map/pd-map-brand-2021.png") 50% 50% no-repeat transparent;
    background-size: contain;
    vertical-align: middle;
  }

}
