.colors-screen {

  text-align: center;

  h2 {
    //margin-top: 140px;
  }

  .colors {
    display: flex;
    width: 250px;
    margin-left: auto;
    margin-right: auto;

    flex-wrap: wrap;
    justify-content: space-between;
  }

  .color {
    width: 75px;
    height: 75px;
    margin-top: 18px;
    padding: 20px 23px;

    border-radius: 75px;
    background-color: #FFEEF3;
    //transition: box-shadow 0.4s 0.0s;
    transition: border 0.4s 0.0s;
  }

  .color--selected {
    padding: 15px 18px;
    border: 5px solid #d6d6d6;
    //box-shadow: 2px 8px 24px rgba(100,100,100,0.5);
  }

  .color__marker {
    width: 29px;
    height: 36px;
  }

  .actions {
    margin-top: 24px;
  }
}
