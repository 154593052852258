.name-screen {
  .name {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }

  .name__input {
    flex: 1;
  }
}
