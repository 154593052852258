.page {
  background-color: #f890ac;
  color: #fff;
  padding-top: 160px;
}

.container {
  padding: 0 40px 40px;
}

// Laptops / desktops
@media (min-width: 1025px) and (max-width: 1280px) {
}

// Tablet portrait.
@media (min-width: 768px) and (max-width: 1024px) {
}

// Tablet portrait and up.
@media (min-width: 768px) {
  .container {
    max-width: 768px;
    padding: 0 116px 0px;

    margin-left: auto;
    margin-right: auto;
  }
}

// Mobile smaller than 400px
@media (min-width: 320px) and (max-width: 400px) {
  .container {
    padding: 0 20px 0px;
  }
}
