@import "../index";

.message-screen {
  h2 {
    margin-bottom: 24px;
  }

  textarea {
    width: 100%;
  }

  .actions__note {
    @extend .small;
    text-align: center;
  }
}
